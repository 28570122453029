<script setup>
const appConfig = useAppConfig();
const store = useStore();
const preferences = usePreferences();
const route = useRoute();

const notifications = ref([]);
const notificationsSlideover = ref(false);
const onlineSlideover = ref(false);

const profileComplete = ref(false)

watch([preferences], () => {
  fetchNotifications()
}, { immediate: true });

watch(() => [store.validated, store.user, store.viewing], () => {
  if (Object.keys(store.user).length) {
    profileComplete.value = !checkUserProfileComplete()
    uncommonLinks.value = uncommonLinksMapper()
  }
}, { deep: true })

const breadcrumb = computed(() => {
  // find the current route.path in uncommonLinks.value and return corresponding item
  let path = route.path.split('/').slice(1)
  let module = uncommonLinks.value[path[0]]
  if (module) {
    return [
      // {
      //   label: module.label,
      //   to: `/${path[0]}`,
      // },
      ...module.children.map((child) => {
        if (child.children) {
          return child.children.map((subchild) => {
            if (subchild.to === `/${path.join('/')}`) {
              return {
                icon: subchild.icon || subchild.sicon,
                label: subchild.label,
                to: subchild.to,
              }
            }
          })
        } else {
          if (child.to === `/${path.join('/')}`) {
            return {
              icon: child.icon || child.sicon,
              label: child.label,
              to: child.to,
            }
          }
        }
      }).flat().filter((item) => item)
    ]
  }
  return []
})

const viewing = computed(() => {
  if (Object.keys(uncommonLinks.value).length - 1 === 1 &&
    Object.keys(uncommonLinks.value)[0] !== store.viewing) {
    store.view(Object.keys(uncommonLinks.value)[0]);
  }

  return uncommonLinks.value[store.viewing];
});

const modulesDropdownLinks = computed(() => {
  let links = {};
  for (const [key, value] of Object.entries(uncommonLinks.value)) {
    if (value.click) {
      links[key] = value;
    }
  }
  return links;
})

const commonLinks = computed(() => {
  let links = [
    {
      label: "Tablou de bord",
      icon: "i-heroicons-home",
      to: "/dashboard/",
    },
    {
      label: "Checkin",
      icon: "i-material-symbols-my-location-outline-rounded",
      to: "/attendance/checkin"
    }
  ]
  if (store.user.role !== "staff") {
    links.push({
      label: "Tranzacții",
      icon: "i-heroicons-receipt-refund",
      to: "/dashboard/invoices",
    })
  }
  return links
})

const uncommonLinks = ref(uncommonLinksMapper())

const groups = ref([
  {
    key: "common",
    // label: "Comune",
    commands: commonLinks.value.map((link) => ({
      ...link,
    })),
  },
  ...Object.entries(uncommonLinks.value).filter(
    ([key, value]) => !value.disabled
  ).map(([key, value]) => ({
    key,
    label: value.label,
    commands: value.children.map((link) => {
      if (link?.children) {
        return [...link?.children.map((child) => {
          return {
            ...child,
            label: `${link.label} - ${child.label}`,
            icon: child.sicon,
          }
        })]
      } else {
        return { ...link }
      }
    }).flat()
  })),
]);

function uncommonLinksMapper() {
  let modules = {}

  if (permissionsAllow("venues")) {
    modules.venues = {
      tag: "venues",
      label: "Evenimente",
      click: () => {
        store.view("venues");
      },
      icon: "i-material-symbols-event-rounded",
      children: [
        {
          label: "Evenimente",
          to: "/venues/events",
          icon: "i-heroicons-calendar",
          children: [
            {
              label: "Calendar",
              sicon: "i-material-symbols-date-range-rounded",
              to: "/venues/events/calendar",
            },
            {
              label: "Planner",
              sicon: "i-material-symbols-early-on-rounded",
              to: "/venues/events/planner",
              badge: {
                color: "green",
                label: "Nou",
              }
            },
            {
              label: "Lista",
              sicon: "i-material-symbols-event-list-rounded",
              to: "/venues/events/list",
            },
          ],
        },
        {
          label: "Banchete",
          to: "/venues/events",
          icon: "i-material-symbols-file-present-outline-rounded",
          children: [
            {
              label: "Meniuri",
              sicon: "i-material-symbols-file-present-outline-rounded",
              to: "/venues/menus",
              active: route.path.includes("/venues/menus"),
            },
            {
              label: "Oferte",
              sicon: "i-heroicons-paper-clip",
              to: "/venues/offers",
              active: route.path.includes("/venues/offers"),
            },
            {
              label: "Șabloane",
              sicon: "i-material-symbols-view-list-outline-rounded",
              to: "/venues/templates",
            },
          ]
        },
        {
          label: "Vizite",
          to: "/venues/leads",
          icon: "i-heroicons-phone-arrow-up-right",
          children: [
            {
              label: "Analitica",
              sicon: "i-heroicons-chart-pie",
              to: "/venues/leads/analytics",
            },
            {
              label: "Lista de apel",
              sicon: "i-heroicons-phone-arrow-up-right",
              to: "/venues/leads/call-list",
            },
            {
              label: "Tabel",
              sicon: "i-heroicons-phone-arrow-up-right",
              to: "/venues/leads/funnel",
              badge: {
                color: "green",
                label: "Nou",
              }
            },
            {
              label: "Toate",
              sicon: "i-heroicons-table-cells",
              to: "/venues/leads/all",
            },
          ]
        },
        {
          label: "Categorii / Produse",
          icon: "i-heroicons-tag",
          to: "/venues/categories",
        },
        {
          label: "Locatii",
          icon: "i-heroicons-map-pin",
          to: "/venues/locations",
        },
      ],
    }
    if (permissionsAllow()) {
      modules.venues.children.unshift({
        label: "Analitica",
        icon: "i-heroicons-chart-pie",
        to: "/venues/analytics",
      })
    }
  }

  if (permissionsAllow("hotel")) {
    modules.hotel = {
      tag: "hotel",
      label: "Lago hotel",
      click: () => {
        store.view("hotel");
      },
      icon: "i-material-symbols-hotel-rounded",
      children: [
        {
          label: "Rezervari",
          to: "/hotel",
          icon: "i-heroicons-calendar",
          children: [
            {
              label: "Calendar",
              sicon: "i-heroicons-calendar",
              to: "/hotel/bookings/calendar",
            },
            {
              label: "Lista",
              sicon: "i-material-symbols-event-list-rounded",
              to: "/hotel/bookings/list",
            },
          ],
        },
        {
          label: "Camere",
          icon: "i-material-symbols-bed-outline-rounded",
          to: "/hotel/rooms",
        },
      ],
    }
  }

  if (permissionsAllow("boxcatering")) {
    modules.boxcatering = {
      tag: "boxcatering",
      label: "Boxcatering",
      click: () => {
        store.view("boxcatering");
      },
      icon: "i-material-symbols-box-rounded",
      children: [
        {
          label: "Oferte",
          icon: "i-heroicons-paper-clip",
          to: "/boxcatering/offers",
          active: route.path.includes("/boxcatering/offers"),
        },
        {
          label: "Banchete",
          icon: "i-material-symbols-file-present-outline-rounded",
          to: "/boxcatering/menus",
          active: route.path.includes("/boxcatering/menus"),
        },
        {
          label: "Comenzi",
          icon: "i-heroicons-shopping-cart",
          to: "/boxcatering/orders",
        },
        {
          label: "Cereri",
          icon: "i-heroicons-inbox",
          to: "/boxcatering/inquiries",
        },
        {
          label: "Categorii / Produse",
          icon: "i-heroicons-tag",
          children: [
            {
              label: "Produse",
              sicon: "i-heroicons-shopping-bag",
              to: "/boxcatering/items",
            },
            {
              label: "Ingrediente",
              sicon: "i-material-symbols-settings-input-component-outline-rounded",
              to: "/boxcatering/ingredients",
            },
          ]
        },
        {
          label: "Setari magazin",
          icon: "i-material-symbols-store-outline-rounded",
          to: "/boxcatering/shop",
        },
        {
          label: "Carusel",
          icon: "i-material-symbols-photo-camera-outline-rounded",
          to: "/boxcatering/hero",
        },
        {
          label: "Vouchere",
          icon: "i-material-symbols-percent-rounded",
          to: "/dashboard/vouchers",
        },
      ],
    }
    if (permissionsAllow()) {
      modules.boxcatering.children.unshift({
        label: "Analitica",
        icon: "i-heroicons-chart-pie",
        to: "/boxcatering/analytics",
      })
    }
  }

  if (permissionsAllow("millerscake")) {
    modules.millerscake = {
      tag: "millerscake",
      label: "Millerscake",
      click: () => {
        store.view("millerscake");
      },
      icon: "i-material-symbols-cake-rounded",
      children: [
        {
          label: "Oferte",
          icon: "i-heroicons-paper-clip",
          to: "/millerscake/offers",
          active: route.path.includes("/millerscake/offers"),
        },
        {
          label: "Comenzi",
          icon: "i-heroicons-shopping-cart",
          to: "/millerscake/orders",
        },
        {
          label: "Categorii / Produse",
          icon: "i-heroicons-tag",
          children: [
            {
              label: "Produse",
              sicon: "i-heroicons-shopping-bag",
              to: "/millerscake/items",
            },
            {
              label: "Catalog torturi",
              sicon: "i-material-symbols-cake-rounded",
              to: "/millerscake/catalog",
            },
          ]
        },
        {
          label: "Vouchere",
          icon: "i-material-symbols-percent-rounded",
          to: "/dashboard/vouchers",
        },
      ],
    }
    if (permissionsAllow()) {
      modules.millerscake.children.unshift({
        label: "Analitica",
        icon: "i-heroicons-chart-pie",
        to: "/millerscake/analytics",
      })
    }
  }

  if (permissionsAllow("attendance")) {
    modules.attendance = {
      tag: "attendance",
      label: "Prezenta",
      click: () => {
        store.view("attendance");
      },
      icon: "i-material-symbols-person-pin-circle-rounded",
      children: [
        {
          label: "Personal",
          icon: "i-material-symbols-person-book",
          to: "/attendance/employees",
        },
        {
          label: "Echipa",
          icon: "i-material-symbols-group-outline-rounded",
          to: "/attendance/team",
        },
        {
          label: "Prezenta la zi",
          icon: "i-material-symbols-today-outline-rounded",
          to: "/attendance/day",
        },
        {
          label: "Locatii",
          icon: "i-material-symbols-add-location-alt-outline-rounded",
          to: "/attendance/locations",
        },
      ],
    }
  }

  if (permissionsAccountant()) {
    modules.accounting = {
      tag: "accounting",
      label: "Contabilitate",
      click: () => {
        store.view("accounting");
      },
      icon: "i-material-symbols-account-balance-rounded",
      children: [
        {
          label: "Rapoarte",
          icon: "i-material-symbols-event-list-outline-rounded",
          to: "/accounting/reports",
        }
      ],
    }
  }
  if (permissionsAccountant("strict")) {
    modules.accounting.children.push(...[
      {
        label: "Ore de munca",
        icon: "i-material-symbols-timer-outline-rounded",
        to: "/accounting/hours",
      },
      {
        label: "Angajati",
        icon: "i-heroicons-user-group",
        to: "/accounting/employees",
      },
      {
        label: "Altele",
        to: "/accounting/other",
        children: [
          {
            label: "Tranzactii",
            sicon: "i-material-symbols-payments-outline-rounded",
            to: "/accounting/other/invoices",
          },
          {
            label: "Oferte",
            sicon: "i-heroicons-paper-clip",
            to: "/accounting/other/offers",
          },
        ]
      },
    ])
  }

  if (permissionsAllow("marketing")) {
    modules.marketing = {
      tag: "marketing",
      label: "Marketing",
      click: () => {
        store.view("marketing");
      },
      icon: "i-material-symbols-ads-click-rounded",
      children: [
        {
          label: "Clienti",
          icon: "i-material-symbols-person-book",
          to: "/marketing/clients",
        },
        {
          label: "Campanii",
          icon: "i-material-symbols-ads-click-rounded",
          to: "/marketing/campaigns",
        }
      ],
    }
  }

  if (permissionsAllow("acquisitions")) {
    modules.acquisitions = {
      tag: "acquisitions",
      label: "Achizitii",
      click: () => {
        store.view("acquisitions");
      },
      icon: "i-material-symbols-local-shipping-rounded",
      badge: {
        color: "orange",
        label: "Test",
      },
      children: [
        {
          label: "Facturi",
          icon: "i-material-symbols-receipt-outline-rounded",
          to: "/acquisitions/bills",
        },
        {
          label: "Calendarul livrarilor",
          icon: "i-material-symbols-event-rounded",
          to: "/acquisitions/orders/calendar",
        },
        {
          label: "Comenzi interne",
          icon: "i-material-symbols-local-shipping-outline-rounded",
          to: "/acquisitions/orders",
        },
        {
          label: "Furnizori & Produse",
          icon: "i-material-symbols-warehouse-outline-rounded",
          to: "/acquisitions/suppliers",
        },
      ],
    }
  }

  if (permissionsAllow()) {
    modules.administration = {
      label: "Administrare",
      icon: "i-material-symbols-admin-panel-settings-outline",
      collapsible: false,
      children: [
        {
          label: "Utilizatori",
          sicon: "i-material-symbols-groups-outline-rounded",
          to: "/administration/users",
        },
      ],
    }
  }

  modules.footer = {
    label: "Altele",
    children: [
      {
        label: "Setari",
        icon: "i-heroicons-cog-8-tooth",
        collapsible: false,
        children: [
          {
            label: "Profil",
            sicon: "i-material-symbols-person",
            to: "/settings/profile",
            exact: true,
          },
          {
            label: "Sistem",
            sicon: "i-material-symbols-settings-outline-rounded",
            to: "/settings/system",
          },
          {
            label: "Securitate",
            sicon: "i-heroicons-lock-closed",
            to: "/settings/security",
          },
        ],
      },
    ]
  }

  if (permissionsAllow()) {
    modules.footer.children.push({
      label: "Progres",
      icon: "i-material-symbols-trending-up-rounded",
      to: "/dashboard/plan",
    })
    modules.footer.children.push({
      label: "Taskuri",
      icon: "i-material-symbols-task-outline-rounded",
      to: "/dashboard/tickets",
    })
  }

  return modules
}

function permissionsAccountant(type = "") {
  if (type == "strict") {
    return ["developer", "admin", "accountant"].includes(store.user.role)
  }

  return ["developer", "admin", "accountant", "manager"].includes(store.user.role) ||
    ["boxcatering_operator_junior", "boxcatering_operator_senior", "boxcatering_manager"].includes(store.user.function);
}
function permissionsAllow(module = "") {
  if (!Object.keys(store.user).length) {
    return false
  }
  return (
    ["developer", "admin"].includes(store.user.role) ||
    Object.keys(store.user.permissions).includes(module)
  );
}
function checkUserProfileComplete() {
  if (store &&
    store.user &&
    store.user.first_name &&
    store.user.last_name &&
    store.user.function &&
    store.user.location_id &&
    store.user.phone &&
    store.user.email &&
    store.user.image &&
    store.user.id_doc.front.image &&
    store.user.id_doc.back.image &&
    store.user.id_doc.idnp &&
    store.user.id_doc.birthday) {
    return true
  }
  return false
}
function fetchNotifications() {
  // return useApi("GET", "/notifications/page/10/1").then((res) => {
  return useApi("POST", "/notifications/latest", {
    data: { modules: preferences.notificationModules }
  }).then((res) => {
    if (res) {
      notifications.value = res.data.map((notification) => {
        return {
          ...notification,
          seen: notification.seen.includes(store.user.id)
        }
      })
    }
  });
}
function viewNotification(id) {
  notifications.value = notifications.value.filter((n) => n.id !== id)
  useApi("GET", `/notifications/seen/${id}`)
  notificationsSlideover.value = false
}
function readAllNotifications() {
  notifications.value = []
  useApi("GET", `/notifications/seen/0`)
}
</script>

<template>
  <!-- <UBadge v-if="true" color="cyan" variant="soft" class="absolute w-full rounded-none">
    <span class="w-full text-center">
      Platforma in curs de modificare. Unele functionalitati pot fi afectate intre orele 18:00 - 20:00.
    </span>
  </UBadge> -->

  <!-- <UDashboardLayout class="pt-6 md:pt-2"> -->
  <UDashboardLayout>
    <LazyUDashboardModal v-if="route.path !== '/settings/profile'" v-model="profileComplete"
      icon="i-material-symbols-warning-rounded" preventClose>
      <template #header>
        <div class="flex gap-4">
          <Icon name="i-material-symbols-warning-rounded" class="h-16 w-20" color="white" />
          <div class="flex flex-col">
            <h2 class="text-lg font-semibold text-gray-900 dark:text-white">Profiulul tau este incomplet.</h2>
            <p class="text-sm text-gray-500 dark:text-gray-400">
              Pentru a continua, completeaza datele contului. Acestea faciliteaza functionarea corecta a platformei.
            </p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex items-end">
          <UButton to='/settings/profile' label="Mergi la profil" color="green" variant="soft" />
        </div>
      </template>
    </LazyUDashboardModal>

    <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
      <LazyUDashboardNavbar v-if="Object.keys(modulesDropdownLinks).length"
        class="!border-transparent bg-primary/10 dark:bg-gray-950/25" :ui="{ left: 'flex-1' }">
        <template #left>
          <MiscModuleDropdown :links="modulesDropdownLinks" :viewing="viewing" />
        </template>
      </LazyUDashboardNavbar>

      <LazyUDashboardSidebar class="bg-primary/10 dark:bg-gray-950/25 backdrop-blur">
        <UDashboardSidebarLinks :links="commonLinks" />

        <template v-if="viewing">
          <UDivider />
          <UDashboardSidebarLinks :links="viewing.children"
            :ui="{ badge: { color: appConfig.ui.primary, variant: 'soft' } }" />
        </template>

        <div class="flex-1" />

        <template v-if="uncommonLinks.administration">
          <UDashboardSidebarLinks :links="[uncommonLinks.administration]" />
          <UDivider />
        </template>

        <UDashboardSidebarLinks :links="uncommonLinks.footer.children" />
        <UDivider class="sticky bottom-0" />

        <template #footer>
          <div class="w-full mb-8">
            <MiscUserDropdown />
          </div>
        </template>
      </LazyUDashboardSidebar>
    </UDashboardPanel>

    <UDashboardPanel grow class="overflow-scroll relative">
      <LazyUDashboardNavbar class="bg-primary/10 dark:bg-gray-950/25 backdrop-blur">
        <template #left>
          <div class="min-w-[150px] flex items-center">
            <UBreadcrumb :links="breadcrumb" />
          </div>
        </template>

        <template #center>
          <UDashboardSearchButton class="w-64 md:w-96" label="Cauta..." />
        </template>

        <template #right>
          <UDashboardSearchButton class="visible md:hidden" label="Cauta..." />

          <UBadge class="hidden md:block" color="primary" variant="soft">
            <div class="flex items-center gap-2">
              <Icon name="i-heroicons-calendar" class="h-6 w-6" color="white" />
              <span>
                {{ formatDateM(new Date()) }}
              </span>
            </div>
          </UBadge>

          <UChip color="green" :text="store.online.length" size="2xl">
            <UButton icon="i-material-symbols-online-prediction-rounded" color="gray" @click="onlineSlideover = true" />
          </UChip>

          <UChip color="red" :text="notifications.map((n) => !n.seen).length" size="2xl">
            <UButton icon="i-heroicons-bell" color="gray" @click="notificationsSlideover = true" />
          </UChip>
        </template>
      </LazyUDashboardNavbar>

      <NuxtPage />
    </UDashboardPanel>

    <ClientOnly>
      <MiscCheckinModal />
      <UDashboardSearch :groups="groups" hide-color-mode>
        <template #empty-state>
          <div class="h-full flex flex-col items-center justify-center gap-3">
            <Icon name="i-material-symbols-search-off-rounded" class="h-8 w-8" />
            <span>Nu am gasit nici o pagina relevanta</span>
          </div>
        </template>
      </UDashboardSearch>
      <!-- <MiscErrorModal /> -->
    </ClientOnly>

    <LazyUDashboardSlideover v-model="onlineSlideover" title="Online acum">
      <div class="flex flex-col">
        <TableUserCell class="p-2" v-for="user in store.online" :user="user" :key="user.id" />
      </div>
    </LazyUDashboardSlideover>

    <LazyUDashboardSlideover v-model="notificationsSlideover" title="Notificari">
      <div class="flex gap-2 mb-4">
        <NotificationsPreferenceSelect />
        <UButton label="Vezi toate" color="gray" icon="i-material-symbols-arrow-menu-open-rounded"
          to="/profile/notifications" @click="notificationsSlideover = false" />
        <UButton label="Marcheaza citite" color="gray" variant="ghost"
          icon="i-material-symbols-mark-email-read-outline-rounded" @click="readAllNotifications" />
      </div>

      <div class="flex flex-col gap-2">
        <p v-if="!notifications.length" class="p-8 text-center text-gray-500 dark:text-gray-400">Nici o notificare noua.
        </p>

        <UPageCard v-for="n in notifications" :title="n.headline" :description="n.content"
          @click="viewNotification(n.id)" :to="n.url" :ui="{
            wrapper: !n.seen ? 'bg-gray-100 dark:bg-gray-800/75' : 'bg-transparent dark:bg-transparent',
            to: 'hover:ring-0',
            ring: 'ring-0',
            body: { padding: 'sm:p-2 p-2' }
          }" />
      </div>
    </LazyUDashboardSlideover>
  </UDashboardLayout>
</template>

<style scoped>
.loading-gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%,
      rgb(var(--color-primary-500) / 0.25) 0,
      #fff 100%);
}

.dark {
  .loading-gradient {
    background: radial-gradient(50% 50% at 50% 50%,
        rgb(var(--color-primary-400) / 0.1) 0,
        rgb(var(--color-gray-950)) 100%);
  }
}

.loading-overlay {
  background-size: 100px 100px;
  background-image: linear-gradient(to right,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px),
    linear-gradient(to bottom,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px);
}

.dark {
  .loading-overlay {
    background-image: linear-gradient(to right,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px),
      linear-gradient(to bottom,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px);
  }
}
</style>
